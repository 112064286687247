<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">{{ product.productName }}</h1>
      <progressIndicator :step="product.step" :steps="product.steps" />
    </div>
    <template v-if="product.step == 1">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep1"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Fejlbesked -->
        <message
          v-show="error.step1"
          :message="error.step1"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 2">
      <form id="step2" v-on:submit.prevent="submitStep2">
        <div class="container small">
          <div class="box">
            <!-- Felter på trin 2 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-if="field.step == 2"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </div>

          <!-- Fejlbesked -->
          <message
            v-show="error.price"
            :message="error.price"
            type="warning"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Pakkekomponent -->
        <packages
          altTitle="Ulykkesforsikringen dækker"
          altLinkText="Læs mere om vores ulykkesforsikringer"
          :packages="product.packages"
          :packageLink="product.packageLink"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <!-- Extra packages -->
        <coverages
          v-model="product.coverages"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <!-- Extra -->
        <package-selection-header-3 v-if="'extra' in this.product" title="Tilkøb ekstra forsikring">
          <extra-view v-for="(extra, i) in this.product.extra" :key="`extra-${i}`" :id="`extra-${i}`" :title="extra.title" :desc="extra.description" :subText="{ type: 'price', obj: product.extraPrices[i] }" :icon="extra.icon" :value="extra.chosen" @input="(value) => extra.chosen = value" :color="extra.color" :width="extra.width" :productId="extra.product.productCode" :productName="extra.title" :productLink="extra.productLink" />
        </package-selection-header-3>

        <div class="container small">
          <!-- Infobesked -->
          <message
            v-show="info.step3"
            :message="info.step3"
            type="info"
            v-on:formFunction="formFunction"
          />
        </div>
      </form>
    </template>

    <!-- Buttons on bottom -->
    <div class="container small">
      <ProgressButtons
        :step="product.step"
        :steps="product.steps"
        :existingPolicy="existingPolicy"
        @changeStep="changeStep"
      />
    </div>

    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :productId="product.productId"
      objectCode="BAR"
      :productLink="product.productLink"
      v-on:contactFromFooter="messageBox"
      alt-title="Mere om vores børneulykkesforsikring"
    >
      Vores børneulykkesforsikring sikrer dit barn en økonomisk erstatning, hvis det kommer ud for en ulykke og får et varigt mén på 5 % eller derover. Forsikringen dækker dit barn hele døgnet, også i institution og skole, frem til barnet fylder 18 år. Opstår der behandlingsudgifter til fysioterapeut, akupunktør eller lignende som følge af ulykken, kan dit barn få dækket disse. Det samme gør sig gældende for tandlægeudgifter, hvis der sker en tandskade i forbindelse med ulykken. Forsikringen dækker også sportsulykker og farlig sport.
    </product-footer>
  </div>
</template>
<script>
import U06 from "@/assets/json/U06_child.json";
import FormInput from "@/components/FormInput.vue";
import progressIndicator from "@/components/progress-indicator.vue";
import ProgressButtons from "@/components/ProgressButtons.vue";
import message from "@/components/message.vue";
import productFooter from "@/components/product-footer.vue";
import packages from "@/components/packages.vue";
import FormStep from "@/components/FormStep.vue";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";
import debounce from "lodash/debounce";
import coverages from "@/components/coverages.vue";
import ExtraView from "@/components/ExtraView.vue";
import PackageSelectionHeader3 from "@/components/PackageSelectionHeader3.vue";

export default {
  name: "U06_Child",
  components: {
    FormInput,
    progressIndicator,
    ProgressButtons,
    message,
    productFooter,
    packages,
    FormStep,
    coverages,
    ExtraView,
    PackageSelectionHeader3,
  },
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  mixins: [commonProduct, validateData],
  data() {
    return {
      productId: "U06",
      objectCode: "BAR",
      product: this.copy(U06),
      temporaryProduct: this.copy(this.$store.state.temporaryProduct),
    };
  },
  created() {
    /*
     * If you edit and existing offer copy the policy here, or if you have temporary object
     */
    if (this.policy) {
      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      this.product = this.copy(this.policy.value);
      //this.existingPolicy = true;
      //this.existingPolicyKey = this.policy.key;
    } else if (
      !this.$route.query.input &&
      this.temporaryProduct &&
      this.temporaryProduct.productId == this.productId &&
      this.temporaryProduct.objectCode == this.objectCode
    ) {
      this.product = this.copy(this.temporaryProduct);
    }
  },
  mounted() {
    // Function if input has come from website
    if (this.$route.query.input) {
      // Get query and delete input
      let query = this.copy(this.$route.query);
      query.input = undefined;

      // Push on without input
      this.$router
        .push({ name: "ulykkesforsikring-barn", query: query })
        .catch(() => {});
    }
  },
  computed: {
    /*
     * Check requirements
     */
    allRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required) {
          if (!value.value || value.error) {
            this.cerror("Not all requirements are met", value);
            return false;
          }
        }
      }
      return true;
    },
  },
  watch: {
    "product.fields": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.coverages": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.extra": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
  },
  methods: {
    /*
     * Reset zipcode
     */
    resetZipcode() {
      this.product.fields.zipCode.value = null;
    },
    /*
     * Set zipcode
     */
    setZipcode(value) {
      this.product.fields.zipCode.value = value.postnummer.nr;
    },
    /*
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {
      let self = this;

      // Update temporary product if product is not existing
      if (!self.existingPolicy) {
        self.$store.commit("temporaryProduct", self.product);
      }

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) return;

      // Remove error
      self.error.price = null;

      // Get submit data without coverages (always calculate with AP)
      let altProduct = this.copy(self.product);
      altProduct.extra.ap.chosen = true;

      const submitData = self.parseSubmitData(altProduct);

      // Console log if in dev, test or beta environment
      this.clog(submitData);

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;

      for (const key in self.product.packages) {
        self.product.packages[key].loading = true;
      }

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then((response) => {
          self.insertPrice(response.data);
        })
        .then(() => {
          // Show popup
          self.$store.commit("showPopup");

          // Update temporary product if product is not existing
          if (!self.existingPolicy) {
            self.$store.commit("temporaryProduct", self.product);
          }

          // If exiting policy, update existing policy
          if (self.existingPolicy) {
            self.updateExistingPolicy(self.product, self.existingPolicyKey);
          }
        })
        .catch(function () {
          // Show error
          self.error.price = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };

          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),
    /*
     * Submit step 1 in form
     */
    async submitStep1() {
      let self = this;

      // Set step 1 to loading
      self.product.steps[0].loading = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

	  // Mod 11 control
      const cprFld = this.product.fields.cprCvr;

      if (!this.checkModulus11(cprFld.value)) {
        cprFld.error = cprFld.validationMessage;

        // Set loading to false
        self.product.steps[0].loading = false;

        return;
      }

      // Test age
      const age = this.$options.filters.cprToAge(this.product.fields.cprCvr.value);
	    this.product.fields.age.value = age;

      if (age >= 18) {
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Ud fra den oplyste alder kan vi desværre ikke beregne en pris online. Ønsker du at købe en ulykkesforsikring til en person over 18 år, henviser vi til ”Ulykkesforsikring – voksen”. Har du brug for personlig rådgivning, kan du udfylde vores kontaktformular. Du vil herefter blive kontaktet af vores kundeservice med et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

		// Set loading to false
		self.product.steps[0].loading = false;

		return;
      }

	  if (age < 2) {
        self.error[`step${self.product.step}`] = {
          headline: "Gratis børneulykkesforsikring til børn under 2 år",
          text: "Hos Aros Forsikring kan du være sikker på mere. Vi vil gerne være med til at give dit barn en god start på livet. Som kunde hos Aros Forsikring tilbyder vi derfor dit barn i alderen fra 0 til 2 år en gratis børneulykkesforsikring. Denne ulykkesforsikring dækker barnet med en invaliditetssum på kr. 1.000.000, tandskade, behandlingsudgifter og begravelseshjælp på kr. 50.000.\n\nUdfyld kontaktformularen, og vores kundeservice kontakter dig for at tegne denne gratis forsikring til dit barn. Du kan endvidere få rådgivning om muligheden for at tilkøbe en højere invaliditetssum.\n\nØnsker du at bestille dine forsikringer samlet, så kan vores kundeservice også give dig et tilbud på dine øvrige forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

		// Set loading to false
		self.product.steps[0].loading = false;

		return;
      }

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set loading to false
      self.product.steps[0].loading = false;
    },
    /*
     * Submit step 2 in form
     */
    submitStep2() {
      // Check if policy is exitting or new
      if (this.existingPolicy) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      } else {
        this.$store.commit("addProduct", this.product);
      }

      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      // Route to shopping basket
      this.$router.push({ name: "Kurv" });
    },
    /*
     * Update existing policy
     */
    updateExistingPolicy(product, key) {
      this.$store.commit("updateProduct", {
        value: product,
        key: key,
      });
    },
  },
};
</script>
