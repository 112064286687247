<template>
  <div class="package-selection-header-3 container small">
    <h3 class="text-align-center margin-top-40 margin-bottom-40">
      {{ title }}
    </h3>
    <div class="coverages">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.package-selection-header-3 {
}
</style>
