<template>
  <div style="position: relative;">
    <div
      :class="{ 'extra-view': true, 'coverage-group': true, chosen: value }"
      :style="_group_style"
    >
      <div>
        <div class="coverage-item">
          <img class="coverage-icon" :src="icon" />
          <div class="coverage-text">
            <span class="coverage-name">{{ title }}</span>
            <span class="coverage-description" v-html="desc" />
          </div>
          <flip-switch
            :value="value"
            @input="(value) => $emit('input', value)"
          />
        </div>
      </div>
      <div
        class="subtext"
        v-if="subText && typeof subText != 'object'"
        style="border-top: 1px solid #e2e2e2"
        v-html="subText"
      />
      <div
        class="subtext"
        v-if="
          subText &&
          typeof subText == 'object' &&
          subText.type == 'price' &&
          subText.obj !== null
        "
        style="border-top: 1px solid #e2e2e2"
      >
        <span class="plus" :style="_plus_style">+</span>
        <span class="price">{{ subText.obj.totalMonthlyPrice }}</span>
        <span class="suffix">kr./md.</span>
      </div>
    </div>
    <div class="basket-product-dropdown" ref="dropdownMenu">
      <button
        class="basket-product-dropdown-button extra"
        @click.prevent="showDropdown = !showDropdown"
      ></button>
      <div class="basket-product-dropdown-content" v-show="showDropdown">
        <a :href="productLink" target="_blank">
          <i class="fa-light fa-circle-info fa-fw"></i>
          <span>Læs mere på hjemmesiden</span>
        </a>
        <a
          href="#"
          @click.prevent="getConditions(productId, productName, 'FOR')"
        >
          <i
            v-if="!loading.conditions"
            class="fa-light fa-file-lines fa-fw"
          ></i>
          <i v-else class="fa-light fa-spinner-third fa-fw icon-spin"></i>
          <span>Hent betingelser</span>
        </a>
        <a
          v-if="true"
          href="#"
          @click.prevent="getFactScheme(productId, productName)"
        >
          <i
            v-if="!loading.factScheme"
            class="fa-light fa-clipboard-check fa-fw"
          ></i>
          <i v-else class="fa-light fa-spinner-third fa-fw icon-spin"></i>
          <span>Hent faktablad</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import downloadDocuments from "@/mixins/downloadDocuments.js";
import FlipSwitch from "./FlipSwitch.vue";

export default {
  mixins: [downloadDocuments],
  components: {
    FlipSwitch,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    productLink: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
    subText: {
      type: [String, Object],
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: "#E0E0E0",
    },
    width: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {
      showDropdown: false,
    };
  },
  computed: {
    _group_style() {
      return {
        borderTop: `${this.width}px solid ${this.color}`,
      };
    },
    _plus_style() {
      return {
        color: `${this.color}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-view {
  position: relative;

  & > .subtext {
    padding: 20px 30px 20px 110px;

    & > span {
      line-height: 30px;
    }

    & > .plus {
      font-size: 24px;
      font-weight: 100;
    }

    & > .price {
      font-size: 24px;
      font-weight: 600;
      margin: 0 10px 0 15px;
    }

    & > .suffix {
      font-size: 12px;
      font-weight: 100;
    }
  }
}
</style>
